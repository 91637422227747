import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";
import { addDays, format } from "date-fns";

import { IVerticalBooking } from "@/interfaces/VerticalBooking/IVerticalBooking";
import { IntlCurrencyMap, IntlLocaleMap } from "@/constants/langCodes";
import { performRequest } from "./datocms";

interface SchemaResponse {
  __schema: {
    types: {
      name: string;
      fields?: {
        name: string;
      }[];
    }[];
  };
}

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export const setLocaleCookie = (locale: string) => {
  if (document) document.cookie = `NEXT_LOCALE=${locale}; Path=/;`;
};

export const getBookingEngineUrl = (verticalBooking: IVerticalBooking, locale: string) => {
  const url = new URL(verticalBooking.domain);

  const searchParams = new URLSearchParams();
  searchParams.append("s", "results");
  searchParams.append("currency", IntlCurrencyMap(locale));
  searchParams.append("locale", IntlLocaleMap(locale));
  searchParams.append("showBestPriceFirst", "1");
  searchParams.append("showPromotions", "3");

  // Relative date parameters
  let startDate = new Date();
  let endDate = addDays(startDate, verticalBooking.notti1 || 1);

  if (verticalBooking.relativeDate) {
    startDate = addDays(startDate, verticalBooking.daysFromToday || 1);
    endDate = addDays(startDate, verticalBooking.notti1 || 1);
  } else {
    if (verticalBooking.checkInDate) startDate = new Date(verticalBooking.checkInDate);
  }
  searchParams.append("arrival", format(startDate, "yyyy-MM-dd"));
  searchParams.append("departure", format(endDate, "yyyy-MM-dd"));

  if (verticalBooking.property) searchParams.append("property", verticalBooking.property);
  if (verticalBooking.totAdulti) searchParams.append("adults1", `${verticalBooking.totAdulti}`);
  if (verticalBooking.totBambini) searchParams.append("children1", `${verticalBooking.totBambini}`);

  url.search = searchParams.toString();

  return url.toString();
};

/* Fetch metadata for all pages for sitemap */
export const getAllPagesMetadata = async () => {
  const IntrospectionQuery = `
    query {
      __schema {
        types {
          name
          fields {
            name
          }
        }
      }
    }
  `;

  const {
    __schema: { types },
  }: SchemaResponse = await performRequest({ query: IntrospectionQuery });
  const pageModels = types.filter((type) => {
    if (!type.fields) return;
    return type.fields.some((field) => field.name === "slug");
  });

  let dynamicPagesQuery = "query AllPagesMetadataQuery {";
  pageModels.forEach((model) => {
    /* Convert model name (ex. HomePageRecord) to model query name (ex. homePage) */
    const modelName = model.name;
    let modelQueryName = modelName.replace("Record", "");
    modelQueryName = modelQueryName.charAt(0).toLowerCase() + modelQueryName.slice(1);

    dynamicPagesQuery += `
    ${modelQueryName} {
        slug
        noindex
        _updatedAt
        _locales
      }
    `;
  });
  dynamicPagesQuery += "}";
  const data = await performRequest({ query: dynamicPagesQuery });
  return data;
};
