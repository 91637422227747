import React from "react";
import clsx from "clsx";

type TypographyProps = {
  variant: string;
  className?: string;
  children: React.ReactNode;
  isHomePage?: boolean;
};

type VariantStyle = {
  tag: React.ElementType;
  classes: string;
};

const variantToStyleMapping: Record<string, VariantStyle> = {
  "hero-title": {
    tag: "h1",
    classes:
      "text-5xl md:text-4.312 text-white font-lora italic font-bold leading-2.925 md:leading-4.575",
  },
  "section-title": {
    tag: "h2",
    classes:
      "text-2.062 cmd:text-5xl text-colorPrimary font-lora italic leading-1.875 cmd:leading-2.925 capitalize",
  },
  "box-title": {
    tag: "h3",
    classes:
      "text-1.438 customMedium:text-2.062 text-colorPrimary font-lora italic font-bold leading-1.875 tracking-0.234 capitalize",
  },
  "hero-subtitle": {
    tag: "h4",
    classes: "text-1.438 font-light font-barlow text-white font-barlow leading-1.875",
  },
  "subtitle-light": {
    tag: "h4",
    classes: "text-1.438 font-light font-barlow leading-1.875",
  },
  "subtitle-bold": {
    tag: "h4",
    classes: "text-xl font-bold text-white font-barlow leading-1.875",
  },
  "paragraph-light": {
    tag: "p",
    classes: "text-1.187 md:text-1.435 font-light text-colorPrimary font-barlow leading-1.662",
  },
  "paragraph-bold": {
    tag: "p",
    classes: "text-base font-extrabold text-colorPrimary font-barlow leading-6",
  },
  "breadcrumbs-normal": {
    tag: "p",
    classes: "text-0.8125 font-normal text-colorPrimary font-barlow leading-1.375",
  },
  "breadcrumbs-bold": {
    tag: "p",
    classes: "text-0.679 md:text-0.8125 font-extrabold uppercase font-barlow leading-5",
  },
  "button-label": {
    tag: "p",
    classes: "text-0.679 font-bold font-barlow leading-sm tracking-0.081 uppercase",
  },
  "span-bold": {
    tag: "span",
    classes: "font-semibold text-colorPrimary font-barlow leading-6",
  },
};

const Typography: React.FC<TypographyProps> = ({ variant, className, children, isHomePage }) => {
  const { tag, classes } = variantToStyleMapping[variant] || variantToStyleMapping.paragraph;
  const Tag = tag;
  const combinedClasses = clsx(classes, className);

  return isHomePage ? (
    <Tag className={combinedClasses} dangerouslySetInnerHTML={{ __html: children }} />
  ) : (
    <Tag className={combinedClasses}>{children}</Tag>
  );
};

export default Typography;
