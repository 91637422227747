import React from "react";
import Link from "next/link";
import { Image as DatoImage } from "react-datocms";

import Typography from "@/components/Typography/Typography";
import CustomButton from "@/components/CustomButton/CustomButton";
import { IErrorPage } from "@/interfaces/ErrorPage/ErrorPage";

type ErrorPageUIProps = IErrorPage;

export default function ErrorPageUI({ data }: { data: ErrorPageUIProps }) {
  const {
    title,
    subtitle,
    backgroundImage,
    navigationButton: { buttonLabel, buttonLink },
  } = data;
  const navButtonLink = buttonLink.slug === "home" ? "/" : `/${buttonLink.slug}`;

  return (
    <div className="min-h-screen w-2/2 flex justify-center items-center">
      <div className="absolute top-0 left-0 right-0 bottom-0">
        <DatoImage
          data={backgroundImage.responsiveImage}
          objectFit="cover"
          layout="fill"
          style={{ filter: "brightness(0.5)" }}
        />
      </div>
      <div className=" absolute flex flex-col justify-center items-center gap-4">
        <Typography variant="hero-title" className="text-white">
          {title}
        </Typography>
        <Typography variant="subtitle-light" className="text-white text-center">
          {subtitle}
        </Typography>
        <Link href={navButtonLink} prefetch={false}>
          <CustomButton variant="outline" value={buttonLabel} />
        </Link>
      </div>
    </div>
  );
}
