"use client";

import React, { use } from "react";
// import { useCurrentLocale } from "next-i18n-router/client";
import { Barlow, Lora } from "next/font/google";

import ErrorPageUI from "@/components/ErrorPageUI/ErrorPageUI";
// import i18nConfig from "../../../i18nConfig";

import { IServerErrorPageResponse } from "@/interfaces/ErrorPage/ErrorPage";
import Typography from "@/components/Typography/Typography";
// import { Config } from "next-i18n-router/dist/types";

const lora = Lora({
  weight: ["700"],
  style: ["normal", "italic"],
  subsets: ["latin"],
  variable: "--font-lora",
  display: "swap",
});
const barlow = Barlow({
  weight: ["300", "600"],
  style: ["normal", "italic"],
  subsets: ["latin"],
  variable: "--font-barlow",
  display: "swap",
});
/*
Custom queryClient to fix the infinite loop issue when using the "use" hook
or when fetching inside a useEffect hook
*/
const fetchMap = new Map<string, Promise<IServerErrorPageResponse>>();
function queryClient(name: string, query: () => Promise<IServerErrorPageResponse>) {
  if (!fetchMap.has(name)) {
    fetchMap.set(name, query());
  }
  return fetchMap.get(name)!;
}

export default function GlobalErrorPage() {
  const { serverErrorPage }: IServerErrorPageResponse = use(
    queryClient("error", () =>
      fetch(`/api/error-page`).then((res) => {
        return res.json();
      }),
    ),
  );

  return (
    <html className={`${lora.variable} ${barlow.variable}`}>
      <body>
        {serverErrorPage ? (
          <ErrorPageUI data={serverErrorPage} />
        ) : (
          <div>
            <Typography variant="hero-subtitle" className="text-center">
              Oops, Something went wrong...
            </Typography>
          </div>
        )}
      </body>
    </html>
  );
}
