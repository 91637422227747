import { cache } from "react";

import vars from "@/config/vars";

// Define types for request parameters and response data
interface RequestParams {
  query: string;
  variables?: Record<string, any>;
  includeDrafts?: boolean;
  excludeInvalid?: boolean;
  revalidate?: number | undefined;
}

interface ResponseData {
  data: any;
  errors: {
    message: string;
  }[];
}

// Define type for the cache function
type FetchFunction = (serializedInit: string) => Promise<ResponseData>;

// const BASE_URL = "https://graphql.datocms.com/";
let BASE_URL: string;

const dedupedFetch: FetchFunction = cache(async (serializedInit) => {
  const response = await fetch(BASE_URL, JSON.parse(serializedInit));
  const responseBody = await response.json();
  if (!response.ok) {
    throw new Error(`${response.status} ${response.statusText}: ${JSON.stringify(responseBody)}`);
  }
  return responseBody;
});

export async function performRequest({
  query,
  variables = {},
  includeDrafts = false,
  excludeInvalid = false,
  revalidate = undefined,
}: RequestParams): Promise<any> {
  try {
    //base usl according to the draft condition
    BASE_URL = includeDrafts
      ? "https://graphql.datocms.com/preview"
      : "https://graphql.datocms.com";
    const result = await dedupedFetch(
      JSON.stringify({
        method: "POST",
        headers: {
          Authorization: `Bearer ${vars.DatoApiToken}`,
          ...(includeDrafts ? { "X-Include-Drafts": "true" } : {}),
          ...(excludeInvalid ? { "X-Exclude-Invalid": "true" } : {}),
          ...(vars.DatoEnv ? { "X-Environment": vars.DatoEnv } : {}),
        },
        body: JSON.stringify({ query, variables, revalidate }),
        next: { revalidate },
      }),
    );
    if (result.data) return result.data;
    else if (result.errors?.length) {
      // throw the first error
      const errorObj = result.errors?.[0];
      let message = "Internal Server Error";

      // eslint-disable-next-line no-console
      console.log(JSON.stringify(result.errors));

      if (errorObj?.message) message = errorObj.message;
      throw new Error(message);
    }
  } catch (error) {
    throw error;
  }
}
