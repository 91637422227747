/* To map locales to ISO 639-2 language codes */
export const LangCodes: Record<string, string> = {
  it: "ita",
  en: "eng",
  ru: "rus",
  zh: "chi",
  de: "deu",
  fr: "fra",
  es: "esp",
  da: "dan",
  ja: "jpn",
  el: "ell",
  pl: "pls",
  sv: "sve",
  tr: "tur",
  nl: "dut",
  pr: "por",
  fi: "fin",
  ko: "kor",
  th: "tha",
  vi: "vie",
  bg: "bul",
  ro: "ron",
  et: "etn",
  hu: "hun",
  sl: "slo",
  cs: "cze",
  nb: "nor",
  no: "nor",
  nn: "nor",
};

export const IntlLocaleMap = (locale: string) => {
  switch (locale) {
    case "it":
      return "it_IT";
    case "en":
      return "en_GB";
    case "es":
      return "es_ES";
    case "zh":
      return "zh_CN";
    default:
      return "en_GB";
  }
};

export const IntlCurrencyMap = (locale: string) => {
  switch (locale) {
    case "it":
    case "es":
      return "EUR";
    case "en":
      return "GBP";
    case "zh":
      return "CNY";
    default:
      return "EUR";
  }
};
